// We are using react for some part in the framework. (comments, forms)
// So make sure these work if you override this

import Mount from 'xp-lib-brand/react/utils/mount.js';

const breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ './modules/breadcrumbs/breadcrumbs.jsx');
const header = () => import(/* webpackChunkName: "header" */ './modules/header/header.jsx');
const usermenu = () => import(/* webpackChunkName: "usermenu" */ './modules/usermenu/usermenu.jsx');

Mount('.member-header-react', header, ['menuItemsMain', 'menuItemsSupport', 'root', 'otherLoginUrl', 'oboLoginUrl', 'producerLoginUrl', 'logouturl', 'userLoggedIn', 'usermenuActivePath']);
Mount('.member-breadcrumbs-react', breadcrumbs, ['classname', 'breadcrumbs', 'usermenuActivePath']);
Mount('.member-usermenu-react', usermenu, ['userLoggedInName', 'isPlussMember', 'menuItemsLoggedIn', 'usermenuActivePath', 'usermenuFirstLevelPath', 'currentPageName']);

